import React, { useEffect, useRef } from 'react';
import { Animated, TouchableWithoutFeedback } from 'react-native';
import styled from '@emotion/native';
import { TouchableRipple } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { FormattedMessage } from 'react-intl';
import { CareshipColors } from './CSTheme';
import Text from './Text';
import { WithoutTheme } from '../Root/Theme/WithoutTheme';

const TouchableButton = styled(TouchableRipple)<{ color: string }>(({ color }) => ({
  backgroundColor: color,
  justifyContent: 'center',
  alignItems: 'center',
  width: 40,
  height: 40,
  borderRadius: 20,
}));

const TouchableWrapper = styled.View({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  height: 40,
});

const LeftLabel = styled<WithoutTheme<typeof Text>>(Text)(({ theme: { breakpoints } }) => [
  {
    marginRight: 16,
  },
  breakpoints.down('sm', { opacity: 0 }),
]);

const AnimatedFeather = Animated.createAnimatedComponent(Feather);

export default function ToggleIconButton({
  createOpen,
  onPress,
}: {
  onPress: () => void;
  createOpen: boolean;
}) {
  const rotation = useRef(new Animated.Value(0)).current;
  useEffect(
    () =>  {
      Animated.spring(rotation, {
        toValue: createOpen ? 1 : 0,
        useNativeDriver: false,
      }).start() },
    [createOpen, rotation],
  );

  return (
    <TouchableWithoutFeedback accessibilityRole="button" onPress={onPress}>
      <TouchableWrapper>
        <LeftLabel size="large" muted>
          {createOpen ? (
            <FormattedMessage id="BUTTON_CANCEL" />
          ) : (
            <FormattedMessage id="BUTTON_ADD" />
          )}
        </LeftLabel>

        <TouchableButton
          rippleColor={CareshipColors.slate300}
          color={CareshipColors.slate400}
          onPress={onPress}
        >
          <AnimatedFeather
            color={CareshipColors.white}
            size={24}
            name="plus"
            style={{
              transform: [
                {
                  rotate: rotation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '45deg'],
                  }),
                },
              ],
            }}
          />
        </TouchableButton>
      </TouchableWrapper>
    </TouchableWithoutFeedback>
  );
}
