import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { View, StyleSheet, Alert, Dimensions, Platform } from 'react-native';
import { WebView } from 'react-native-webview';
import {Feather} from "@expo/vector-icons";
import Button from "../../Component/Button";
import {CareshipColors} from "../../Component/CSTheme";
import {FormattedMessage} from "react-intl";

const SignatureScreen = forwardRef((props, ref) => {
  const webviewRef = useRef(null);
  const canvasRef = useRef(null);
  const [signatureData, setSignatureData] = useState(null);
  const { width } = Dimensions.get('window');
  const [isEmpty, setIsEmpty] = useState(true); // Track if the canvas is empty
  // Expose methods to parent component
  useImperativeHandle(ref, () => ({
    getSignature() {
      if (Platform.OS === 'web') {
        getSignatureFromCanvas();
      } else if (webviewRef.current) {
        webviewRef.current.injectJavaScript('getSignature();');
      }
    }
  }));

  const handleClear = () => {
    if (Platform.OS === 'web') {
      clearSignatureCanvas();
    } else if (webviewRef.current) {
      webviewRef.current.injectJavaScript('clearSignature();');
    }
    setSignatureData(null);
  };

  const handleMessage = (event) => {
    const { data } = event.nativeEvent;
    if (data.startsWith('data:image')) {
      if (props.onSignatureReceived) {
        props.onSignatureReceived(event.nativeEvent.data);
      }
    } else {
      Alert.alert('Signature', 'No signature entered');
    }
  };

  const getSignatureFromCanvas = () => {
    const canvas = canvasRef.current;
    if (!isEmpty && canvas) {
      const dataUrl = canvas.toDataURL('image/png');
      setSignatureData(dataUrl);
      if (props.onSignatureReceived) {
        props.onSignatureReceived(dataUrl);
      }
    }
    else {
      Alert.alert('Signature', 'No signature entered');
    }
  };

  const clearSignatureCanvas = () => {
    setIsEmpty(true)
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };


  useEffect(() => {
    if (Platform.OS === 'web' && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let drawing = false;
      let previous = null;


      const resizeCanvas = () => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        ctx.scale(ratio, ratio);
      };

      const getPosition = (event) => {
        const rect = canvas.getBoundingClientRect(); // Get the canvas' bounding box
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;

        // Support both touch and mouse events
        const clientX = event.touches ? event.touches[0].clientX : event.clientX;
        const clientY = event.touches ? event.touches[0].clientY : event.clientY;

        return {
          x: (clientX - rect.left + scrollX) * (canvas.width / rect.width),
          y: (clientY - rect.top + scrollY) * (canvas.height / rect.height),
        };
      };

      const startDrawing = (event) => {
        drawing = true;
        setIsEmpty(false)
        previous = getPosition(event);
      };

      const draw = (event) => {
        if (!drawing) return;
        const current = getPosition(event);
        ctx.beginPath();
        ctx.moveTo(previous.x, previous.y);
        ctx.lineTo(current.x, current.y);
        ctx.stroke();
        previous = current;
      };

      const stopDrawing = () => {
        drawing = false;
        previous = null;
      };

      //resizeCanvas();
      //window.addEventListener('resize', resizeCanvas);

      canvas.addEventListener('mousedown', startDrawing);
      canvas.addEventListener('mousemove', draw);
      canvas.addEventListener('mouseup', stopDrawing);
      canvas.addEventListener('mouseleave', stopDrawing);

      // Event listeners for touch events
      canvas.addEventListener('touchstart', startDrawing, { passive: false });
      canvas.addEventListener('touchmove', draw, { passive: false });
      canvas.addEventListener('touchend', stopDrawing);

      return () => {
        // Cleanup mouse events
        canvas.removeEventListener('mousedown', startDrawing);
        canvas.removeEventListener('mousemove', draw);
        canvas.removeEventListener('mouseup', stopDrawing);
        canvas.removeEventListener('mouseleave', stopDrawing);

        // Cleanup touch events
        canvas.removeEventListener('touchstart', startDrawing);
        canvas.removeEventListener('touchmove', draw);
        canvas.removeEventListener('touchend', stopDrawing);
      };
    }
  }, []);

  const signaturePadHtml = `
    <!DOCTYPE html>
    <html>
    <head>
      <style>
        body, html { margin: auto; padding: 0; width: calc(100vw - 120px); height: 200px; display: flex; justify-content: center; align-items: center; }
        canvas { border: 1px solid black; margin: auto; touch-action: none; width: calc(100vw - 120px); height: 200px; }
      </style>
    </head>
    <body>
      <canvas id="signature-pad"></canvas>
      <script>
        (function() {
          const canvas = document.getElementById('signature-pad');
          const ctx = canvas.getContext('2d');
          let drawing = false;
          let previous = null;
          let emptyCanvas = true;

          function resizeCanvas() {
            const ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            ctx.scale(ratio, ratio);
          }

          function getPosition(event) {
            const rect = canvas.getBoundingClientRect();
            return {
              x: (event.clientX - rect.left) * (canvas.width / rect.width),
              y: (event.clientY - rect.top) * (canvas.height / rect.height)
            };
          }

          function startDrawing(event) {
            drawing = true;
            previous = getPosition(event);
            emptyCanvas = false;
          }

          function draw(event) {
            if (!drawing) return;
            const current = getPosition(event);
            ctx.beginPath();
            ctx.moveTo(previous.x, previous.y);
            ctx.lineTo(current.x, current.y);
            ctx.stroke();
            previous = current;
          }

          function stopDrawing() {
            drawing = false;
            previous = null;
          }

          function clearSignature() {
              emptyCanvas = true;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }

          function getSignature() {
            const dataUrl = emptyCanvas?  '-': canvas.toDataURL('image/png');
            window.ReactNativeWebView.postMessage(dataUrl);
          }

          window.clearSignature = clearSignature;
          window.getSignature = getSignature;

          canvas.addEventListener('mousedown', startDrawing);
          canvas.addEventListener('mousemove', draw);
          canvas.addEventListener('mouseup', stopDrawing);
          canvas.addEventListener('mouseleave', stopDrawing);

          canvas.addEventListener('touchstart', (e) => {
            e.preventDefault();
            startDrawing(e.touches[0]);
          });
          canvas.addEventListener('touchmove', (e) => {
            e.preventDefault();
            draw(e.touches[0]);
          });
          canvas.addEventListener('touchend', (e) => {
            e.preventDefault();
            stopDrawing();
          });
          canvas.addEventListener('touchcancel', (e) => {
            e.preventDefault();
            stopDrawing();
          });
        })();
      </script>
    </body>
    </html>
  `;

  return (
    <View style={styles.container}>
      {Platform.OS === 'web' ? (
        <canvas
          ref={canvasRef}
          style={{
            border: '1px solid black',
            width: `300px`,
            height: '100px',
            marginBottom: '10px'
          }}
        />
      ) : (
        <WebView
          ref={webviewRef}
          originWhitelist={['*']}
          source={{ html: signaturePadHtml }}
          javaScriptEnabled={true}
          onMessage={handleMessage}
          style={[styles.webview, { width: width - 50, height: 250 }]}
        />
      )}
      <View style={styles.buttons}>
        <Button
          style={styles.redoButton}
          onPress={handleClear}
          size="sm"
          labelStyle={{ color: CareshipColors.secondary }}
          icon={<Feather color={CareshipColors.secondary} size={16} name="refresh-cw" />}
        >
          <FormattedMessage id="BUTTON_UNDO_SIGNATURE_SCREEN" />
        </Button>
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  container: {
    maxHeight: 250,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webview: {
    borderColor: 'black',
    borderWidth: 0,
    width: '100%',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 0,
    width: '100%',
  },
  redoButton: {
    backgroundColor: 'white',
    borderStyle: "solid",
    borderColor: CareshipColors.secondary,
    borderWidth: 1
  },
});


export default SignatureScreen;
